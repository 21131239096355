@font-face {
    font-family: "skia-embed";
    src: url("/fonts/skia.woff2") format("woff2"),
         url("/fonts/skia.woff") format("woff"),
         url("/fonts/skia.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "alice-regular";
    src: url("/fonts/Alice-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}