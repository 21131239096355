$accent-color: #32A6BC;
$br-large: 900px;


$font-size-body: 1rem;
$font-weight-body: 300;
$font-size-h1: 1.8125rem;
$font-size-h2: 1.55rem;
$font-size-h3: 1.2rem;
$font-weight-h: 400;
$font-weight-bold: 700;

@import "sbe/fonts";
@import "sbe/navigation";
// @import "sbe/sidebar";

/* Basics */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    font-size: 100%;
}

html,body {
    height: 100vh;
}

body {
    display: block;
}

blockquote {
    margin: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

audio {width: 100%;}

svg {
    height: 100%; 
    width: 100%;
}

/* Main Section */

body {
    font-family: 'Jost', 
                system, 
                -apple-system, ".SFNSText-Regular", "San Francisco", 
                "Roboto", "Segoe UI", 
                "Helvetica Neue", "Lucida Grande", sans-serif;
    line-height: 1.6;
    font-weight: $font-weight-body;
    font-size: $font-size-body; //18px
}


h1,h2,h3,h4 {
    // font-family: 'skia-embed',sans-serif;
    font-family: 'alice-regular',sans-serif;
    line-height: 1.25em;
    font-weight: $font-weight-h;
    margin-block-end: 0.25em;
}

h1 {font-size: $font-size-h1;}
h2 {font-size: $font-size-h2;}
h3 {font-size: $font-size-h3;}
h4 {font-size: $font-size-h3;
    font-weight: $font-weight-body;}

strong {font-weight: $font-weight-bold;}

a {
    color: $accent-color;
    text-decoration: none;
    &:hover {
        color: black;
        border-bottom: 1px dotted gray;
    }
}

blockquote {
    background-color: #fafafa;
    border-left: 4px solid #e6e6e6;
    border-right: 4px solid transparent;
    padding: 0rem 1rem;
    overflow: auto;
    margin: 1rem 0;
    color: #7a7a7a;
}

.player {
    margin: 1rem auto;
}


$sidebar-width: 19rem;

#content {
    padding: 2rem;
    // height: 100vh;

    article {
        // max-width: 45em;
        // background-color: lightgreen;
        // margin-top: 10rem;
    }
}

.episode-actions {
    color: grey;
    fill: grey
}


.post-list_item {
    margin-bottom: 2rem;
}



#header-toggle{
    display: none;
    &:checked{
    &~#sidebar .sidebar-nav {
        display: none;
    }
    &~#sidebar .sidebar-footer {
        display: none;
    }

}}

.header-toggle-label {
    display: none;
}

#sidebar {
    padding: 1rem 2rem;
    
    //flexbox
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &::-webkit-scrollbar{
        display: none;
    }
    
    background-color: $accent-color;
    text-align: center;
    align-items: center;

    

    .sidebar-about {
        height: 3rem;
        display: flex;
        .site-description {
            display: none;
        }

        .site-logo {
            display: none; //can't work out how to make it work
            width: 100%;
            max-width: 100%;
            // min-height: 100%;
        }

        .site-title {
            display: flex;
            margin: auto 0.5em;
            a {
                all:unset;
                cursor: pointer;
            }
            // flex: 0 0 75%;
            white-space: nowrap;
            // font-family: 'skia-embed';
            font-family: 'alice-regular';
        }

        
            
        
        // display: inline-block;
        // .site-logo {
            // display: block;
            // height: 100%;
            // width: 2rem;
        // }
        // position: relative;
        // flex: 1;
        
        /* keep pinned on top, not scrolling off */
    
        // height: 300px;
        // margin-top: 1em;
        // margin-bottom: 1em;
    }


    a {
        /* supports both text and icon hyperlinks
           for svg support */
        color: white;
        fill: white;
        &:hover {
            color: black;
            fill:black;
            border-bottom: none;
        };

    }


    .site-logo {
        // margin-bottom: 1rem;
    }
    .site-title {
        // font-size: $font-size-h1;
        // line-height: 1;
        margin-top: 1em;
        margin-bottom: 1em;
        // font-family: "skia-embed",sans-serif;
        // font-weight: bold;
    }
    .site-description  {
        // font-weight: 300;
        // font-size: 1.25rem;
        // width: 90%;
        // margin: auto;
    }

    .sidebar-nav {
        list-style: none;
        color: white;
        padding: 0;
        // margin: 1em auto;
    }


    .players{
        // flex: 200px;
    }
    .social{
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        font-size: 1rem;
    }

    .copyright {
        font-size: 0.75rem;
    }
}





@media screen and (min-width: $br-large) {
    #sidebar {
        // own position
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: $sidebar-width; /* to match v1 design */
        // background-color: hotpink;
        // font-size: 1.25rem;

        label {width: 100%;}

        padding: 2rem 2rem 1rem 2rem;
        .sidebar-about {
            display: block;
            height: initial;

            .site-description {
                display: initial;
            }
            .site-title {
                margin: 1rem;
                white-space: initial;
                font-size: 1.8rem;
                font-size: 2rem;
            };
            .site-logo {
                display: block;
                padding: 0 2rem;
            }
        }

        nav {
            margin-top: auto;
            font-size: 1.25rem;
        }
        .sidebar-nav {
            margin-top: auto;
        }

        .sidebar-footer {
            margin-top: auto;
        }

    }

    #content {
    //own position
    margin-left: $sidebar-width;
    // margin-right: 2rem;
    max-width: 50rem;
    overflow: auto;
    }

    .host_list {
        // width: 30rem;
    }
}


/* needs rework, straight import */

.host_list {
    // width: 30rem;
    // margin: auto;
}

.col{
    // display: block;
    float: left;
    // height: 100%;
    
}
.left {
    width: 25%;
    height: 100%;

    
};
.right {
    width: 75%;
    padding-left: 20px;
    margin-right: auto;
};

.right h2 {margin-bottom: 0;}
.right p {margin-block-start: 0.5em;}
.row {
    display: flex;
    align-items: center;
}

.row:after {
    content: ""; 
    display: block; 
    clear: both;
}

.about_h1 {
    font-weight: bold;
    color: $accent-color
}

.host_img {
    max-width: 80%;
    margin: auto;
}

.contact-link {
    margin-left: 0.5em;
}

.episode-list {
    list-style-type: none;
    padding: 0;
}
