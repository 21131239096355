

.navigation {
    // max-width: calc(100% - 0px);
    // margin: 0 auto;
    // margin-top: 60px;
    // display: flex;
    // flex-direction: column;
    a {
    //   font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      color: $accent-color;
      border: 2px solid $accent-color;
      line-height: 1.25;
    //   text-transform: uppercase;
      &:hover {
        padding: 14px;
        border: 2px solid $accent-color;
        border-width: 3px;
      }

      &:first-child:last-child{
          margin:0
      }
    //   &:nth-child(2) {
    //     margin-top: 10px;
    //   }
    //   &:first-child:last-child {
    //     width: 100%;
    //   }
    }
    .fa {
      font-size: 0.8rem;
    }
    .navigation-prev {
      text-align: left;
      margin-bottom: 1rem;
      .fa {
        padding-right: 10px;
      }
      .navigation-tittle {
        padding-left: 0.5rem;
      }
    }
    .navigation-next {
      text-align: right;
      .fa {
        padding-left: 10px;
      }
      .navigation-tittle {
        padding-right: 0.5rem;
      }
    }
  }
  .navigation-single a {
    text-transform: none;
  }

@media screen and (min-width: $br-large){

    .navigation{
        display: flex;
        a {
            flex: 50%;
            margin: 0;
        }
        .navigation-prev {margin:0; margin-right: 1rem;}
        .navigation-next {margin:0; margin-left: 1rem;}
    }
}